<template>
  <div>

    <el-row class="cc">
        <el-col :span="18" :offset="3">
          <div class="top_dh">
            <!-- 面包屑导航 -->
            <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span>信创融合</span></p>
          </div>
          <!-- 页面标题 -->
          <p class="IntroductionTitle">国家信创战略</p>
        </el-col>
      </el-row>
      <el-row class="Introduction cw">
        <el-col :span="18" :offset="3" style="margin-bottom: 20px;">
          <div class="contectBox">
            <div class="mt">
              <p style="color: #888;line-height: 24px;text-indent: 2em;display: block;font-size: 16px; margin:0px 0 20px">磐石信创融合平台（磐石云裳）积极响应国家信创战略，为企事业单位提供了从终端到应用到平台的全栈解决方案，通过全面技术契合以及应用、基础设施的全方位适配，支持多类型操作系统同时发布和统一管理，实现用户业务系统和关键应用的平滑迁移，帮助用户构建完整信创体系，在保证业务系统正常运转同时大大提高了业务平台的可靠性和生产数据的安全性，同时有效降低了运维成本。</p>
              <img class="xccc" style="width: 65%;" src="@/assets/ecology/g1.png" />
            </div>
          </div>
        </el-col>
      </el-row>
    <el-row class="cc ptb">
      <el-col :span="18" :offset="3">
        <p class="IntroductionTitle" style="margin-bottom: 20px;">信创市场机遇</p>
        <div class="mt">
          <p style="color: #888;line-height: 24px;text-indent: 2em;display: block;font-size: 16px; margin:0px 0 20px;">在全球产业从工业化向数字化升级的关键时期，中国明确提出“数字中国”建设战略，以抢占下一时期的技术优先权。但2018年以来，受“华为、中兴事件”影响，我国科技尤其是上游核心技术受制于人的现状对我国经济持续高质量发展提出了严峻考验，为了摆脱这一现状，国家将信创产业纳入国家战略，提出“2+8”发展体系，2020-2022年中国IT产业在基础硬件、基础软件、行业应用软件、信息安全等诸多领域迎来了黄金发展期。
<br/>&emsp;&emsp;据《中国信创产业发展白皮书(2021)》显示，到2023年，全球计算产业市场空间1.14万亿美元，中国计算产业市场空间接近全球的10%。按照50%为信创产业市场规模计算，2023年中国信创产业市场规模将突破3650亿元，市场容量将突破万亿。</p>
          <img class="xccc" style="width: 65%;" src="@/assets/ecology/g2.png" />
        </div>
      </el-col>
    </el-row>
    <el-row class="cw ptb">
      <el-col :span="18" :offset="3">
        <p class="IntroductionTitle" style="margin-bottom: 20px;">信创变革挑战</p>
        <div class="mt">
          <img class="xccc" style="width: 80%;" src="@/assets/ecology/g3.png"/>
        </div>
      </el-col>
    </el-row>
    <!-- 磐石信创融合解决方案 -->
    <el-row class="cc ptb">
      <el-col :span="18" :offset="3">
        <p class="IntroductionTitle">磐石信创融合解决方案</p>
        <div class="mt">
          <p style="color: #888;line-height: 24px;text-indent: 2em;display: block;width: 67%;font-size: 16px; margin:0px 0 20px 17%;">磐石信创融合平台（磐石云裳）积极响应国家信创战略，为企事业单位提供了从终端到应用到平台的全栈解决方案，通过全面技术契合以及应用、基础设施的全方位适配，支持多类型操作系统同时发布和统一管理，实现用户业务系统和关键应用的平滑迁移，帮助用户构建完整信创体系，在保证业务系统正常运转同时大大提高了业务平台的可靠性和生产数据的安全性，同时有效降低了运维成本。</p>
          <img class="xccc" src="@/assets/ecology/e12.png" />
        </div>
      </el-col>
    </el-row>
    <!-- 磐石信创融合产品线 -->
    <el-row class="cw ptb">
      <el-col :span="18" :offset="3">
        <p class="IntroductionTitle">磐石信创融合产品线</p>
        <div class="xcd">
          <div class="fl">
            <br/>
            <p>历经数次产品迭代，磐石已完成以云桌面产品解决方案为主，包含云终端、终端管理系统、高价值应用上云、一体机、安全网关、以及Hyper-X等功能组件在内的完整产品布局。</p>
            <br/>
            <p>其中云终端产品全面覆盖x86、ARM等主流芯片技术，累计推出八个型号十六款标准化产品，并完成与国产主流操作系统的全面适配；一体机产品通过构建轻量级基础计算服务平台，面向工作组级用户提供迅捷交付服务，用户开箱即用；安全网关产品则面向互联网用户及跨域集团客户提供可靠的安全接入服务，极大延伸了云桌面解决方案应用场景，使得“随时桌面”成为可能。</p>
            <br/>
            <p>磐石更加重视行业用户需求并关注行业细分市场，  面向高端设计类应用用户推出了“应用上云”云服务解决方案，合理优化用户设计类应用资源，打造高价值应用资源池，系统支持对资源有效利用情况实时跟踪并及时分析，支持资源主动回收，有效保护用户投资。</p>
            <br/>
            <p>磐石以其完整的产品形态、卓越的产品性能、全行业细分市场解决方案的全面响应以及面向高价值设计应用市场应用服务的全力打造，为良好市场预期奠定了坚实基础。</p>
          </div>
          <div class="fl">
            <img src="@/assets/ecology/e10.png"/>
          </div>
          <div class="clearfix"></div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name:'Ecology',
  data(){
    return{
      descShow:true,
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('EcologyMobile')
    }
  },
  methods:{
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  }
}
</script>

<style>
.ptb{
  padding: 3% 0;
}
.top_dh{
  margin-bottom: 20px;
}
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: hsl(0, 0%, 40%);
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
.contectimg{
  width: 57%;
  float: left;
  margin-right: 5%;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.contectimg img{
  width: 100%;
}
.cc{background: #f5f5f5;padding-bottom: 30px;}
.cw{background: #fff;}
.rightbtn{text-align: right;}
.mt{margin-top: 3%;}
.contectBox{
  margin-top: 30px;
}
.Introduction{
  position: relative;
}
.IntroductionTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
}
.IntroductionDesc{
  font-size: 18px;
  color: #888;
  line-height: 31px;
}
.IntroductionDesc h6{
  font-size: 26px;
  line-height: 40px;
  font-weight: bold;
  color: #000;
}
.IntroductionDesc p{
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
  text-align: justify;
  
}

.mt{margin-top: 30px;}
/*产品特点*/
.proChara{
  text-align: left;
  width: 96%;
  margin-left: 5.5%;
}
.proChara ul{
  width: 16.666%;
  display: block;
  float: left;
}
.proChara ul:first-child{
  width: 17%;
}
.proChara ul:nth-child(2){
  width: 19%;
}
.proChara ul:nth-child(3){
  width: 15.6%;
}
.proChara ul:nth-child(6){
  width: 14%;
}
.proChara ul:nth-child(5){
  width: 14.1%;
}

.proChara li{
  list-style: disc;
  line-height: 24px;
  padding-right: 15%;
}
.frameworkList{
  text-align: center;
  margin-top:2%;
  padding-top: 2%;
  padding-bottom: 2%;
  /* margin: 0 12.5% 3% 12.5%;
  padding: 44px 0 48px 0; */
}
.frameworkList > img{
  width: 100%;
}
.imga{
  padding-bottom: 30px;
}
.imga img{
  width: 85%;
  display: block;
  margin: 10px auto;
}
.xcb{margin-top: 2%;}
.IntroductionTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
}
.xcba{
  width: 45%;
  padding-top: 2%;
}
.fl{float:left;}
.fr{float:right;}
.xcba p{
  color: #888;
  text-indent: 2em;
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
  margin-top:2%
}
.xcbb{width: 54%;}
.xcbb img{width: 100%;}
.xcc{
  margin-top: 2%;
}
.xcca{
  display: flex;
  justify-content: center;
}
.clearfix{clear:both;}
.xcca img:first-child{
  width: 48%;
  margin-right: 2%;
}
.xcca img:last-child{
  width: 50%;
}
.xcc p{
  color: #888;
  text-indent: 2em;
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
  margin-bottom:1%

}
.tit{
  font-size: 24px;
  margin-bottom: 2%;
}
.xccb{
  text-align:center;
  padding: 10% 15%;
  border: 1px solid #666;
  margin-bottom: 40px;
}
.xccb img{
  height: 78px;
}
.xccb p{
  color: #888;
  text-align: left;
  line-height: 24px;
  height: 144px;
}
.xccb h6{
  font-size: 20px;
  margin: 6% 0;
}
/* .cb{
  background: #f5f5f5;
  padding: 3% 0;
}
.cc{
  background: #fff;
  padding: 3% 0;
} */
.xccc{
  width: 73%;
  display: block;
  margin: 0 auto;
}
.xcd{margin-top: 3%;}
.xcd .fl:first-child{width: 46%;margin-right: 4%;}
.xcd .fl p{color: #888; line-height: 24px;text-indent: 2em;text-align: justify;}
.xcd .fl:nth-child(2){width: 50%;}
.xcd .fl:nth-child(2) img{width: 95%;display: block;margin: 0 auto;}

.xce{
  padding-top: 3%;
}
.xce .fl{
  width: 50%;
}
.xce .fr{
  width: 50%;
}
.xce img{
  width: 95%;
  display: block;
  margin: 0 auto;
}
.xce p{
  color: #888;
  line-height: 24px;
  text-indent: 2em;
  width: 70%;
  margin-left: 10%;
  margin-top: 5%;
}
@media screen and (min-width: 1500px){
  .proChara li{
    line-height: 30px;
  }
}
</style>